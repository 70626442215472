import { Api } from '../../../resources/api-constants';
import CustomAxios from '../../../utility/customAxios';
import { PaginationExtractor } from '../../../utility/helpers/pagination-extractor';
import { IProduct } from '../interface/product.interface';

// Admin Service
export const listAdminProducts = (query: any) => {
	return CustomAxios.get(`${Api.ALL_ADMIN_PRODUCTS}${PaginationExtractor(query)}`);
};

// Seller Service
export const listSellerProducts = (query: any) => {
	return CustomAxios.get(`${Api.ALL_Seller_PRODUCTS}${PaginationExtractor(query)}`);
};

export const getSellerProduct = (id: any) => {
	return CustomAxios.get(`${Api.Get_PRODUCT}/${id}`);
};

export const updateSellerProductApi = (update: IProduct) => {
	return CustomAxios.put(`${Api.UPDATE_PRODUCT}/${update._id}`, update);
};

export const deleteSellerProduct = (id: string) => {
	return CustomAxios.delete(`${Api.DELETE_PRODUCT}/${id}`);
};

export const addSellerProduct = (data: IProduct) => {
	return CustomAxios.post(`${Api.ADD_PRODUCT}`, data);
};

export const uploadMainImageService = (body: any) => {
	return CustomAxios.post(`${Api.UPLOAD_MAIN}/${body.id}`, body.image);
};

export const uploadGallayImageService = (body: any) => {
	return CustomAxios.post(`${Api.UPLOAD_GALLARY}/${body.id}`, body.image);
};