import { Api } from '../../../resources/api-constants';
import CustomAxios from '../../../utility/customAxios';
import { PaginationExtractor } from '../../../utility/helpers/pagination-extractor';

// Seller Service
export const listSellerOrdersApi = (query: any) => {
	return CustomAxios.get(`${Api.ALL_SELLER_ORDERS}${PaginationExtractor(query)}`);
};

export const getOrderDetailsApi = (id: string) => {
	return CustomAxios.get(`${Api.GET_ORDER_DETAILS}/${id}`);
};

export const updateOrderStatusApi = (data : any) => {
	return CustomAxios.put(`${Api.UPDATE_ORDER_STATUS}/${data.id}` , data)
}

// Admin Service
export const listAdminOrdersApi = (query: any) => {
	return CustomAxios.get(`${Api.ALL_ORDERS}${PaginationExtractor(query)}`);
};