import React, { useEffect, useState } from 'react'
import GridjsComponent from '../../../components/gridjs/Gridjs.component'
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component'
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { html } from 'gridjs'
import { ROUTES } from '../../../resources/routes-constants'
import { Link } from 'react-router-dom'
import { _ } from 'gridjs-react'
import { getAdminKitchensAction } from '../actions/kitchen.actions'
import { ArrayFromNumber } from '../../../utility/helpers/array-from-number.helper'
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import { useLoading } from '../../../provider/IsLoading.provider'

const KitchensListPage: React.FC<any> = () => {
	const assets = process.env.REACT_APP_ASSETS_URL
  const breadcrumb: Breadcrumb = {
    title: 'Categories',
    links: [
      { name: 'Dashboard', path: '/' },
      { name: 'Categories', path: '/kitchen/list' }
    ]
  }
  const dispatch = useAppDispatch()
  const { setLoading } = useLoading()
  const { kitchens, status } = useAppSelector((state) => state.kitchen)
  const [columns] = useState([
    {
      name: 'ID',
      sort: true,
      data: (e: any) => {
        return _(
          <a href={'/kitchens/view/' + e._id} className="text-body">
            {e._id}
          </a>
        )
      }
    },
    {
      name: 'Name',
      sort: true,
      data: (e: any) => {
        return _(
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                <img src={assets + e.logo} alt="" className="img-fluid d-block" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <a href={'/kitchens/view/' + e._id} className="text-body">
                  {e.name.En}
                </a>
              </h5>
            </div>
          </div>
        )
      }
    },
    {
      name: 'Rate',
      sort: true,
      data: (e: any) => {
        return _(
          <div className="flex-grow-1">
            <div className="fs-16 align-middle text-warning">
              {ArrayFromNumber(e.rating).map((item: any) => (
                <i key={item} className="ri-star-fill"></i>
              ))}
              {ArrayFromNumber(5 - e.rating).map((item: any) => (
                <i key={item} className="ri-star-line"></i>
              ))}
            </div>
          </div>
        )
      }
    },
    {
      name: 'isActive',
      sort: true,
      data: (e: any) => {
        return html(
          e.isActive
            ? '<span class="badge bg-success-subtle text-success text-uppercase">Active</span>'
            : '<span class="badge bg-danger-subtle text-danger text-uppercase">Inactive</span>'
        )
      }
    },
    {
      name: 'Action',
      width: '110px',
      data: (e: any) => {
        return _(
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
              <a href={'/kitchens/view/' + e._id} className="text-primary d-inline-block">
                <i className="ri-eye-fill fs-16"></i>
              </a>
            </li>
          </ul>
        )
      }
    }
  ])
  
  useEffect(() => {
    if (status == REDUX_STATUS.SUCCEEDED || status == REDUX_STATUS.FAILED) {
      setLoading(false)
    }
  }, [setLoading, status])

  useEffect(() => {
    if (status == REDUX_STATUS.IDLE) {
      setLoading(true)
      dispatch(
        getAdminKitchensAction({
          offset: 1,
          limit: 12,
          orderBy: 'createdAt',
          orderDirection: SORT_DIRECTION.ASC
        })
      )
    }
  }, [dispatch, kitchens, setLoading, status])

  return (
    <div className="container-fluid">
      <div className="row">
        <BreadcrumbComponent breadcrumb={breadcrumb} />
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div>
            <div className="card">
              <div className="card-header border-0">
                <div className="row g-4">
                  <div className="col-sm-auto">
                    <div>
                      <Link to={ROUTES.CATEGORIES_CREATE} className="btn btn-success" id="addproduct-btn">
                        <i className="ri-add-line align-bottom me-1"></i> Add Category
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div className="search-box ms-2">
                        <input type="text" className="form-control" id="searchProductList" placeholder="Search Products..." />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div id="table-product-list-all" className="table-card gridjs-border-none">
                  <GridjsComponent columns={columns} data={kitchens} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KitchensListPage
