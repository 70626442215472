import { createAsyncThunk } from '@reduxjs/toolkit'
import { addCategory, editCategory, getAdminCategories, getSellerCategories, getcategoryById, uploadImageService } from '../services/category.service'

export const getAdminCategoriesAction = createAsyncThunk('getAdminCategories/get', async (data: any, thunkApi) => {
	try {
		const response = await getAdminCategories(data)
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
})

export const getSellerCategoriesAction = createAsyncThunk('getSellerCategories/get', async (data: any, thunkApi) => {
	try {
		const response = await getSellerCategories(data)
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
})

export const getCategoryByIdAction = createAsyncThunk('getCategoryById/get', async (id: string, thunkApi) => {
	try {
		const response = await getcategoryById(id)
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
})

export const addCategoryAction = createAsyncThunk('addCategory/post', async (data: any, thunkApi) => {
	try {
		const response = await addCategory(data)
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
})

export const editCategoryAction = createAsyncThunk('addCategory/put', async (data: {id:string, formData: any}, thunkApi) => {
	try {
		const response = await editCategory(data.id, data.formData)
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
})

export const uploadImageAction = createAsyncThunk('uploadImageAction/post', async (data: { id: string; image: any }, thunkApi) => {
	try {
		const response = await uploadImageService(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});