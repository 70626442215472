import axios from 'axios'

const apiUrl = process.env.REACT_APP_BACKEND_URL
const customAxios = axios.create({
  baseURL: `${apiUrl}`,
  headers: {
    'x-custom-lang': localStorage.getItem('lng') ?? 'ar',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Methods': '*',
    location: localStorage.getItem('LOCATION') ?? null
  }
})

let token = JSON.parse(localStorage.getItem('TK')!)

customAxios.interceptors.request.use(
  async (config) => {
    if (token) {
      config.headers['Authorization'] = `Bearer ${token.accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

customAxios.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    if (error.response.status == 401) {
      originalRequest._retry = true
      const refreshToken = token.refreshToken
      token = null as any
      try {
        customAxios.defaults.headers.common['Authorization'] = `Bearer ${refreshToken}`
        const rs = await customAxios.post('user/auth/refresh')
        localStorage.setItem('TK', JSON.stringify(rs.data.data))
        token = rs.data.data

        return customAxios(originalRequest)
      } catch (_error) {
        localStorage.removeItem('TK')
        localStorage.removeItem('PROFILE')
        location.assign('/auth/login')
        originalRequest._retry = true
        return Promise.reject(_error)
      }
    }
    return Promise.reject(error)
  }
)

export default customAxios
