import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

const DropzoneComponent: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    maxFiles: 4,
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles([...acceptedFiles] as any)

      acceptedFiles.map((file: any) => {
        const reader = new FileReader()
        reader.onloadend = function () {
          Object.assign(file, {
            preview: reader.result! as string
          })
        }
        reader.readAsDataURL(file)
      })
    }
  })

  const thumbs = files.map((file: any) => (
    <li key={file.name} className="mt-2" id="dropzone-preview-list">
      <div className="border rounded">
        <div className="d-flex justify-content-between p-2">
          <div className="me-3">
            <div className="avatar-xl bg-light rounded">
              <img
                className="img-fluid rounded d-block"
                src={file.preview}
                alt="Product-Image"
                onLoad={() => {
                  URL.revokeObjectURL(file.preview)
                }}
              />
            </div>
          </div>
          <div className="ms-3">
            <button type="button" className="btn btn-sm btn-danger">
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </div>
      </div>
    </li>
  ))

  const images = props.images?.map((file: any) => (
    <li key={file} className="mt-2" id="dropzone-preview-list">
      <div className="border rounded">
        <div className="d-flex justify-content-between p-2">
          <div className="me-3">
            <div className="avatar-xl bg-light rounded">
              <img
                className="img-fluid rounded d-block"
                src={file}
                alt="Product-Image"
                onLoad={() => {
                  URL.revokeObjectURL(file)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </li>
  ))

  useEffect(() => {
    props.uploadedFiles(files)
  }, [files, props])

  return (
    <>
      {/* <small className="text-muted">{t('Validation.MaxAllowedFiles')}</small> */}
      <div className="dropzone">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="dz-message needsclick">
            <div className="mb-3">
              <i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
            </div>

            <h5>{t('Common.DropZone')}</h5>
          </div>
        </div>
      </div>
      <small className="text-muted d-block">{t('Validation.MaxAllowedSize', { count: 1})}</small>
      <small className="text-muted d-block">{t('Validation.MaxAllowedFiles', { count: 4 })}</small>
      <ul>{thumbs}</ul>
      <ul>{images}</ul>
    </>
  )
}

export default DropzoneComponent
