import { Api } from "../../../resources/api-constants";
import { IOffer } from "../interface/offer.interface";
import CustomAxios from '../../../utility/customAxios';

export const getAdminOffers = (query: any) => {
	return CustomAxios.get(`${Api.SELLER_OFFERS}?offset=${query.offset}&perPage=${query.limit}&orderDirection=asc`);
};

export const getOfferById = (id: string) => {
	return CustomAxios.get(`${Api.GET_OFFER}/${id}`);
};

export const addOffer = (data: IOffer) => {
	return CustomAxios.post(`${Api.ADD_OFFER}`, data);
};

export const editOffer = (id: string, data: IOffer) => {
	return CustomAxios.put(`${Api.EDIT_OFFER}/${id}`, data);
};

export const deleteOffer = (id: string) => {
	return CustomAxios.delete(`${Api.DELETE_OFFER}/${id}`);
};
