import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import React from 'react'

const CKEditorComponent: React.FC<any> = (props) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={props.input}
      onBlur={(event, editor) => {
        const data = editor.getData()
        props.output({target: {name: props.fieldName, value: data}});
      }}
    />
  )
}

export default CKEditorComponent
