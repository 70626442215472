import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAdminOffers, getOfferById, addOffer, editOffer, deleteOffer } from '../services/offer.service'


export const getOffersAction = createAsyncThunk('getOffersAction/get', async (data: any, thunkApi) => {
  try {
    const response = await getAdminOffers(data)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getOfferByIdAction = createAsyncThunk('getOfferById/get', async (id: string, thunkApi) => {
  try {
    const response = await getOfferById(id)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const addOfferAction = createAsyncThunk('addOffer/post', async (data: any, thunkApi) => {
  try {
    const response = await addOffer(data)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const editOfferAction = createAsyncThunk('addOffer/put', async (data: { id: string; formData: any }, thunkApi) => {
  try {
    const response = await editOffer(data.id, data.formData)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const deleteOfferAction = createAsyncThunk('deleteOffer/post', async (id: string, thunkApi) => {
  try {
    const response = await deleteOffer(id)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})
