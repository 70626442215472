export const GetYear = (date: string | Date) => {
  if (date) {
    return new Date(date).getFullYear()
  }
}

export const FormatDate = (date: string | Date) => {
  if (date) {
    return new Date(date).toLocaleDateString('en-GB')
  }
}
