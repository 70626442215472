import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../provider/Auth.provider';

const AuthGuard = (props: { children: any }) => {
	const isAuth = useContext(AuthContext)
	if (Object.keys(isAuth).length == 0) {
		return <Navigate to="/auth/login" replace />;
	} else {
		return props.children;
	}
};

export default AuthGuard;
