import React, { useEffect } from 'react'
import DashboardPage from './modules/Dashboard.page'
import NotFoundPage from './modules/NotFound.page'
import { ROUTES } from './resources/routes-constants'
import LayoutComponent from './layout/Layout.component'
import ProductsListPage from './modules/products/pages/Products.list.page'
import { Routes, Route } from 'react-router-dom'
import CategoriesListPage from './modules/categories/pages/Categories.list.page'
import CategoryCreatePage from './modules/categories/pages/Category.create.page'
import SigninPage from './modules/auth/pages/Signin.page'
import AuthGuard from './Guards/Auth.Guard'
import ProductCreatePage from './modules/products/pages/Product.create.page'
import CategoryEditPage from './modules/categories/pages/Category.edit.page'
import ProductViewPage from './modules/products/pages/Product.view.page'
import ProductEditPage from './modules/products/pages/Product.edit.page'
import RoleGuard from './Guards/Role.Guard'
import SignupPage from './modules/auth/pages/Signup.page'
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'
import { useAppSelector } from './store/store'
import KitchenViewPage from './modules/kitchen/pages/Kitchen.view.page'
import AttributesListPage from './modules/attribute/pages/Attribute.list.page'
import KitchensListPage from './modules/kitchen/pages/Kitchen.list.page'
import KitchenEditPage from './modules/kitchen/pages/Kitchen.edit.page'
import { OrdersListPage } from './modules/orders/pages/Orders.page'
import ProfileEditPage from './modules/profile/pages/profile.edit.page'
import { OrdersDetailsPage } from './modules/orders/pages/Order-Details.page'
import { SpecialOrdersListPage } from './modules/special-orders/pages/Special-Orders.page'
import { SpecialOrdersDetailsPage } from './modules/special-orders/pages/Special-Order-Details.page'
import OffersListPage from './modules/offer/pages/offer.list.page'

const RootComponent: React.FC = () => {
  const toastObj = useAppSelector((state) => state.layout.toastObj)

  useEffect(() => {
    if (toastObj.show) {
      Toastify({
        text: toastObj.message,
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'center',
        className: toastObj.type,
        stopOnFocus: true
      }).showToast()
    }
  }, [toastObj])

  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />

      <Route path={ROUTES.SIGNIN} element={<SigninPage />} />
      <Route path={ROUTES.SIGNUP} element={<SignupPage toaster={Toastify}/>} />

      <Route path={ROUTES.HOMEPAGE} element={<AuthGuard><LayoutComponent /></AuthGuard>}>
        <Route index element={<DashboardPage />} />

        <Route path={ROUTES.KITCHEN_LIST} element={<RoleGuard><KitchensListPage /></RoleGuard>} />
        <Route path={ROUTES.KITCHEN_VIEW} element={<KitchenViewPage />} />
        <Route path={ROUTES.KITCHEN_EDIT} element={<KitchenEditPage />} />

        <Route path={ROUTES.CATEGORIES_LIST} element={<RoleGuard><CategoriesListPage /></RoleGuard>} />
        <Route path={ROUTES.CATEGORIES_CREATE} element={<RoleGuard><CategoryCreatePage /></RoleGuard>} />
        <Route path={ROUTES.CATEGORIES_EDIT} element={<RoleGuard><CategoryEditPage /></RoleGuard>} />

        <Route path={ROUTES.ATTRIBUTES_LIST} element={<RoleGuard><AttributesListPage /></RoleGuard>} />

        <Route path={ROUTES.PRODUCTS_VIEW} element={<ProductViewPage />} />
        <Route path={ROUTES.PRODUCTS_EDIT} element={<ProductEditPage />} />
        <Route path={ROUTES.PRODUCTS_LIST} element={<ProductsListPage />} />
        <Route path={ROUTES.PRODUCTS_CREATE} element={<ProductCreatePage />} />
        
        <Route path={ROUTES.ORDERS_LIST} element={<OrdersListPage />} />
        <Route path={ROUTES.ORDERS_DETAILS} element={<OrdersDetailsPage />} />
        
        <Route path={ROUTES.SPECIAL_ORDERS_LIST} element={<SpecialOrdersListPage />} />
        <Route path={ROUTES.SPECIAL_ORDERS_DETAILS} element={<SpecialOrdersDetailsPage />} />
        
        <Route path={ROUTES.OFFER_LIST} element={<OffersListPage />} />
        
        <Route path={ROUTES.PROFILE_EDIT} element={<ProfileEditPage />} />
        
      </Route>
    </Routes>
  )
}

export default RootComponent
