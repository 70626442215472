import React from 'react'
import { Breadcrumb } from './breadcrumb.interface'
import { Link } from 'react-router-dom'

const BreadcrumbComponent: React.FC<{ breadcrumb: Breadcrumb }> = ({ breadcrumb }) => {
  return (
    <div className="col-12">
      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 className="mb-sm-0">{breadcrumb.title}</h4>

        <div className="page-title-right">
          <ol className="breadcrumb m-0">
            {breadcrumb.links.map((link, index) => {
              return (
                <li key={link.name} className={index == breadcrumb.links.length - 1 ? "breadcrumb-item active" : "breadcrumb-item"}>
                  <Link to={link.path}>{link.name}</Link>
                </li>
              )
            })}
          </ol>
        </div>
      </div>
    </div>
  )
}

export default BreadcrumbComponent
