import { Api } from '../../resources/api-constants';
import customAxios from '../../utility/customAxios';

export const uploadImageService = (body: any) => {
	return customAxios.post(Api.UPLOAD_PROFILE_IMAGE, body);
};

export const getProfileService = () => {
	return customAxios.get(Api.GET_PROFILE);
};

export const updateProfileService = (body: any) => {
	return customAxios.patch(Api.UPDATE_PROFILE, body);
};

export const updatePasswordService = (body: any) => {
	return customAxios.patch(Api.UPDATE_PASSWORD, body);
};
