import React, { createContext } from 'react'
import { useAppSelector } from '../store/store'
import { IUser } from '../modules/profile/interface/User.interface'

const initValue: IUser = {} as IUser

const ProfileContext = createContext(initValue)

export const RoleProvider = ({ children }: any) => {
  const profileStorage = JSON.parse(localStorage.getItem('PROFILE')!)
  const { contents } = useAppSelector((state: any) => state.profile)
  const profile = profileStorage ?? contents

  return <ProfileContext.Provider value={profile}>{children}</ProfileContext.Provider>
}

export default ProfileContext
