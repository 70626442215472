import { ICategory } from '../interface/Category.interface';
import CustomAxios from '../../../utility/customAxios';
import { Api } from '../../../resources/api-constants';
import { PaginationExtractor } from '../../../utility/helpers/pagination-extractor';

export const getAdminCategories = (query: any) => {
	return CustomAxios.get(`${Api.ADMIN_CATEGORIES}${PaginationExtractor(query)}`);
};
export const getSellerCategories = (query: any) => {
	return CustomAxios.get(`${Api.SELLER_CATEGORIES}${PaginationExtractor(query)}`);
};

export const getcategoryById = (id: string) => {
	return CustomAxios.get(`${Api.GET_CATEGORY}/${id}`);
};

export const addCategory = (data: ICategory) => {
	return CustomAxios.post(`${Api.ADD_CATEGORY}`, data);
};

export const editCategory = (id: string, data: ICategory) => {
	return CustomAxios.put(`${Api.EDIT_CATEGORY}/${id}`, data);
};

export const uploadImageService = (body: any) => {
	return CustomAxios.post(`${Api.UPLOAD_CATEGORY_IMAGE}/${body.id}`, body.image);
};