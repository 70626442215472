import { Api } from '../../resources/api-constants'
import CustomAxios from '../../utility/customAxios'

export const loginService = (body: any) => {
  return CustomAxios.post(Api.LOGIN, body)
}

export const signupService = (body: any) => {
  return CustomAxios.post(Api.SIGNUP, body)
}

export const refreshTokenService = () => {
  return CustomAxios.post(Api.REFRESH_TOKEN)
}
