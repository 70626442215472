import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import RootComponent from './RootComponent'
import { persistor, store } from './store/store'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './provider/Auth.provider'
import { RoleProvider } from './provider/Profile.provider'
import { languageInitialization } from './utility/language'
import { io } from 'socket.io-client'
import { LoadingProvider } from './provider/IsLoading.provider'

const REACT_BACKEND_URL = process.env.REACT_APP_ASSETS_URL
const socket = io(REACT_BACKEND_URL!)

const App: React.FC = () => {
  languageInitialization()

  useEffect(() => {
    if (localStorage.getItem('KITCHEN')) {
      const kitchenId = JSON.parse(localStorage.getItem('KITCHEN')!)._id
      socket.on(`order-create_${kitchenId}`, (data) => {})

      socket.on('special-order', (data) => {})
      socket.on('special-offer_5da11e48-d964-417d-bdc4-28d62d44a38d', (data) => {console.log(data)})
      socket.on('order-update_5da11e48-d964-417d-bdc4-28d62d44a38d', (data) => {console.log(data)})
    }
  }, [])

  return (
    <BrowserRouter basename="/">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <RoleProvider>
              <LoadingProvider>
                <RootComponent />
              </LoadingProvider>
            </RoleProvider>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  )
}

export default App
