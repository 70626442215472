import React, { useEffect, useState } from 'react'
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component'
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface'
import CKEditorComponent from '../../../components/ckeditor/CkEditor.component'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { getAttributesAction } from '../../attribute/actions/attribute.actions'
import { DELIVARY_TYPE } from '../../../utility/enums/delivary-type.enum'
import { DISCOUNT_TYPE } from '../../../utility/enums/discount-type.enum'
import { ICategory } from '../../categories/interface/Category.interface'
import DropzoneComponent from '../../../components/dropzone/Dropzone.component'
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum'
import { useTranslation } from 'react-i18next'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_green.css'
import { Translation } from '../../../utility/helpers/translation.helper'
import { validate } from '../../../utility/helpers/form-validation.helper'
import { getSellerProductAction, updateSellerProductAction, uploadGallayImageAction, uploadMainImageAction } from '../actions/product.actions'
import { useParams } from 'react-router-dom'
import { IAddon, IOptions, IProduct } from '../interface/product.interface'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import { IKitchen } from '../../kitchen/interface/kitchen.interface';

const ProductEditPage: React.FC<any> = () => {
	const kitchen: IKitchen = JSON.parse(localStorage.getItem("KITCHEN")!)

  const [mainImage, setMainImage] = useState('/assets/images/products/img-1.png')
  const [mainPreview, setMainPreview] = useState('/assets/images/products/img-1.png')
  const [mainChange, setMainChange] = useState(false)
  const [gallaryImages, setGallaryImages] = useState([])
  const [gallaryChange, setGallaryChange] = useState(false)
	const [categories, setCategories] = useState([] as Array<ICategory>)

  const { attributes, status: attributeStatus } = useAppSelector((state) => state.attribute)
  const params = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const breadcrumb: Breadcrumb = {
    title: t('Products'),
    links: [
      { name: t('Dashboard'), path: '/' },
      { name: t('Products'), path: '/products/list' },
      { name: t('Edit'), path: '/products/edit/' + params.id }
    ]
  }

  const [formData, setFormData] = useState({} as IProduct)

  useEffect(() => {
    dispatch(getSellerProductAction(params.id!)).then((response: any) => {
      setFormData(response.payload.data)
      setMainPreview(response.payload.data?.mainImage)
    })
    if (categories.length == 0 && kitchen) {
      setCategories(kitchen.categories)
    }

    if (attributeStatus == REDUX_STATUS.IDLE) {
      dispatch(
        getAttributesAction({
          offset: 1,
          limit: 12,
          order: {
            orderBy: 'createdAt',
            orderDirection: SORT_DIRECTION.ASC
          }
        })
      )
    }
  }, [dispatch, params, attributeStatus, categories.length, kitchen])

  const changeHandler = (event: any, index?: number) => {
    const { name, value } = event.target

    switch (name) {
      case 'value':
      case 'price':
      case 'stock':
      case `discountType${index}`:
      case 'discountValue': {
        setFormData((prevFormData) => ({
          ...prevFormData,
          options: [
            ...prevFormData.options.map((item: any, i: number) => {
              if (i == index) {
                return name == `discountType${index}`
                  ? {
                      ...item,
                      discountType: value
                    }
                  : {
                      ...item,
                      [name]: value
                    }
              } else {
                return item
              }
            })
          ]
        }))
        break
      }
      case 'attribute': {
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
        setFormData((prevFormData) => ({
          ...prevFormData,
          options: [
            {
              price: 0,
              value: '',
              stock: null,
              discountType: DISCOUNT_TYPE.NO_DISCOUNT,
              discountValue: 0
            }
          ],
          addons: [
            {
              name: {
                En: '',
                Ar: ''
              },
              price: 0,
              isOptional: true
            }
          ]
        }))
        break
      }
      case 'nameEn': {
        setFormData((prevFormData) => ({ ...prevFormData, name: { ...prevFormData.name, En: value } }))
        break
      }
      case 'nameAr': {
        setFormData((prevFormData) => ({ ...prevFormData, name: { ...prevFormData.name, Ar: value } }))
        break
      }
      case 'descriptionEn': {
        setFormData((prevFormData) => ({ ...prevFormData, description: { ...prevFormData.description, En: value } }))
        break
      }
      case 'descriptionAr': {
        setFormData((prevFormData) => ({ ...prevFormData, description: { ...prevFormData.description, Ar: value } }))
        break
      }
      case 'contentEn': {
        setFormData((prevFormData) => ({ ...prevFormData, content: { ...prevFormData.content, En: value } }))
        break
      }
      case 'contentAr': {
        setFormData((prevFormData) => ({ ...prevFormData, content: { ...prevFormData.content, Ar: value } }))
        break
      }
      case 'metaTitle': {
        setFormData((prevFormData) => ({ ...prevFormData, metadata: { ...prevFormData.metadata, title: value } }))
        break
      }
      case 'metaKeywords': {
        setFormData((prevFormData) => ({ ...prevFormData, metadata: { ...prevFormData.metadata, keywords: value } }))
        break
      }
      case 'metaDescription': {
        setFormData((prevFormData) => ({ ...prevFormData, metadata: { ...prevFormData.metadata, description: value } }))
        break
      }
      case 'addonNameEn': {
        setFormData((prevFormData) => ({
          ...prevFormData,
          addons: [
            ...prevFormData.addons.map((item: any, i: number) => {
              if (i == index) {
                item.name.En = value
              }
              return item
            })
          ]
        }))
        break
      }
      case 'addonNameAr': {
        setFormData((prevFormData) => ({
          ...prevFormData,
          addons: [
            ...prevFormData.addons.map((item: any, i: number) => {
              if (i == index) {
                item.name.Ar = value
              }
              return item
            })
          ]
        }))
        break
      }
      case 'addonPrice': {
        setFormData((prevFormData) => ({
          ...prevFormData,
          addons: [
            ...prevFormData.addons.map((item: any, i: number) => {
              if (i == index) {
                item.price = value
              }
              return item
            })
          ]
        }))
        break
      }
      case 'isOptional' + index: {
        setFormData((prevFormData) => ({
          ...prevFormData,
          addons: [
            ...prevFormData.addons.map((item: any, i: number) => {
              if (i == index) {
                return { ...item, isOptional: JSON.parse(value) }
              } else {
                return item
              }
            })
          ]
        }))
        break
      }
      default: {
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
      }
    }
  }

  const addOptionValue = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      options: [
        ...formData.options,
        {
          price: 1,
          value: '',
          stock: null,
          discountType: DISCOUNT_TYPE.NO_DISCOUNT,
          discountValue: 0
        }
      ]
    }))
  }

  const addProductAddon = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      addons: [
        ...formData.addons,
        {
          name: {
            En: '',
            Ar: ''
          },
          price: 0,
          isOptional: true
        }
      ]
    }))
  }

  const mainUploadHandler = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      setMainPreview(reader.result! as string)
    }
    reader.readAsDataURL(file)
    setMainImage(e.target.files[0])
    setMainChange(true)
  }

  const gallaryUploadHandler = (e: any) => {
    if (e.length > 0) {
      setGallaryImages(e)
      setGallaryChange(true)
    }
  }

  const submitHandler = () => {
    validate(document.getElementsByClassName('needs-validation')[0] as HTMLFormElement).then((validate) => {
      if (validate) {
        dispatch(updateSellerProductAction(formData)).then((update: any) => {
          if (update.payload.success) {
            if (gallaryChange) {
              const data: FormData = new FormData()
              for (const item in gallaryImages) {
                data.append('files', gallaryImages[item])
              }
              data.append('fieldName', 'gallary')
              dispatch(uploadGallayImageAction({ id: update.payload.data._id, image: data }))
            }
            if (mainChange) {
              const data = new FormData()
              data.append('file', mainImage)
              data.append('fieldName', 'mainImage')
              dispatch(uploadMainImageAction({ id: update.payload.data._id, image: data }))
            }
          }
        })
      }
    })
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <BreadcrumbComponent breadcrumb={breadcrumb} />
      </div>

      <form id="createproduct-form" autoComplete="off" className="needs-validation" noValidate>
        <div className="row">
          <div className="col-lg-8">
            <div className="card">
              <div className="card-header">
                <ul className="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#english" role="tab">
                      {t('Common.English')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#arabic" role="tab">
                      {t('Common.Arabic')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#general" role="tab">
                      {t('Common.GeneralInfo')}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  <div className="tab-pane active" id="english" role="tabpanel">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="product-title-input">
                        {t('Form.NameEn')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="product-title-input"
                        value={formData.name?.En}
                        placeholder={t('Form.NameEn')}
                        name="nameEn"
                        onChange={changeHandler}
                        required
                      />
                      <div className="invalid-feedback">{t('Validation.Name')}</div>
                    </div>
                    <div className="mb-3">
                      <label>{t('Form.ShortDescription')}</label>
                      <textarea
                        className="form-control"
                        placeholder={t('Form.ShortDescription')}
                        rows={3}
                        value={formData.description?.En}
                        name="descriptionEn"
                        onChange={changeHandler}></textarea>
                    </div>
                    <div>
                      <label>{t('Form.ContentEn')}</label>
                      <div id="ckeditor-english">
                        <CKEditorComponent fieldName={'contentEn'} input={formData.content?.En} output={changeHandler} placeholder={t('Form.ContentEn')} />
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="arabic" role="tabpanel">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="product-title-input">
                        {t('Form.NameAr')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="product-title-input"
                        placeholder={t('Form.NameAr')}
                        name="nameAr"
                        value={formData.name?.Ar}
                        onChange={changeHandler}
                        required
                      />
                      <div className="invalid-feedback">{t('Validation.Name')}</div>
                    </div>
                    <div className="mb-3">
                      <label>{t('Form.ShortDescription')}</label>
                      <textarea
                        className="form-control"
                        placeholder={t('Form.ShortDescription')}
                        rows={3}
                        value={formData.description?.Ar}
                        name="descriptionAr"
                        onChange={changeHandler}></textarea>
                    </div>
                    <div>
                      <label>{t('Form.ContentAr')}</label>
                      <div id="ckeditor-arabic">
                        <CKEditorComponent fieldName={'contentAr'} input={formData.content?.Ar} output={changeHandler} placeholder={t('Form.ContentAr')} />
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="general" role="tabpanel">
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="product-title-input">
                          {t('Form.DelivaryType')}
                        </label>
                        <div className="mt-4 mt-lg-0">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="delivaryType"
                              id="group"
                              value={DELIVARY_TYPE.GROUP_ORDER}
                              onChange={changeHandler}
                              checked={formData.delivaryType == DELIVARY_TYPE.GROUP_ORDER}
                            />
                            <label className="form-check-label" htmlFor="group">
                              {t('Form.GroupOrder')}
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="delivaryType"
                              id="single"
                              value={DELIVARY_TYPE.SINGLE_ORDER}
                              onChange={changeHandler}
                              checked={formData.delivaryType == DELIVARY_TYPE.SINGLE_ORDER}
                            />
                            <label className="form-check-label" htmlFor="single">
                              {t('Form.SingleOrder')}
                            </label>
                          </div>
                        </div>
                      </div>
                      {formData.delivaryType == DELIVARY_TYPE.GROUP_ORDER && (
                        <div className="col-md-6">
                          <label className="form-label" htmlFor="product-title-input">
                            {t('Form.DeliverdDate')}
                          </label>
                          <Flatpickr
                            className="form-control"
                            value={formData.delivaryDate!}
                            onChange={([date]: any) => {
                              changeHandler({ target: { name: 'delivaryDate', value: date } })
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="attributes">
                          {t('Form.Attribute')}
                        </label>
                        <select
                          className="form-select"
                          id="attributes"
                          name="attribute"
                          data-choices="true"
                          value={formData.attribute?._id}
                          data-choices-search-false="true"
                          onChange={changeHandler}>
                          {attributes?.map((attr) => {
                            return (
                              <option key={attr._id} value={attr._id}>
                                {Translation(attr.name)} Per ({attr.unit})
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {formData.options?.length > 0 && (
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <ul className="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" data-bs-toggle="tab" href="#variants" role="tab">
                        {t('Common.VariantsInfo')}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-bs-toggle="tab" href="#addons" role="tab">
                        {t('Common.Addons')}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="card-body">
                  <div className="tab-content">
                    <div className="tab-pane active" id="variants" role="tabpanel">
                      {formData.options?.map((val: IOptions, x: number) => (
                        <div key={x} className="border-bottom mb-4 pb-3">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label" htmlFor={'value' + x}>
                                  {t('Common.Value')}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={'value' + x}
                                  placeholder={t('Common.Value')}
                                  name="value"
                                  value={formData.options[x].value}
                                  onChange={(e) => changeHandler(e, x)}
                                  required
                                />
                                <div className="invalid-feedback">{t('Validation.Value')}</div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label" htmlFor={'stock' + x}>
                                  {t('Common.Stock')}
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id={'stock' + x}
                                  placeholder={t('Common.Stock')}
                                  name="stock"
                                  value={formData.options[x].stock!}
                                  onChange={(e) => changeHandler(e, x)}
                                />
                                <div className="invalid-feedback">{t('Validation.Stock')}</div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label className="form-label" htmlFor={'discountType' + x}>
                                {t('Common.DiscountType')}
                              </label>
                              <div className="mt-4 mt-lg-0">
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={'discountType' + x}
                                      value={DISCOUNT_TYPE.NO_DISCOUNT}
                                      checked={formData.options[x].discountType == DISCOUNT_TYPE.NO_DISCOUNT}
                                      onChange={(e) => changeHandler(e, x)}
                                    />
                                    {t('Common.NoDiscount')}
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="discountType"
                                    id={'fixed' + x}
                                    value={DISCOUNT_TYPE.FIXED}
                                    checked={formData.options[x].discountType == DISCOUNT_TYPE.FIXED}
                                    onChange={(e) => changeHandler(e, x)}
                                  />
                                  <label className="form-check-label">{t('Common.Fixed')}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={'discountType' + x}
                                      value={DISCOUNT_TYPE.PERCENT}
                                      checked={formData.options[x].discountType == DISCOUNT_TYPE.PERCENT}
                                      onChange={(e) => changeHandler(e, x)}
                                    />
                                    {t('Common.Percent')}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">{t('Common.Price')}</label>
                                <div className="input-group has-validation mb-3">
                                  <span className="input-group-text">{t('Common.EGP')}</span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder={t('Common.Price')}
                                    name="price"
                                    value={formData.options[x].price}
                                    onChange={(e) => changeHandler(e, x)}
                                    required
                                  />
                                  <div className="invalid-feedback">{t('Validation.Price')}</div>
                                </div>
                              </div>
                            </div>
                            {formData.options[x].discountType != DISCOUNT_TYPE.NO_DISCOUNT && (
                              <div className="col-lg-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="form-label">{t('Common.Discount')}</label>
                                  <div className="input-group mb-3">
                                    <span className="input-group-text">{formData.options[x].discountType == DISCOUNT_TYPE.FIXED ? t('Common.EGP') : '%'}</span>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="discountValue"
                                      value={formData.options[x].discountValue}
                                      onChange={(e) => changeHandler(e, x)}
                                      placeholder={t('Common.Discount')}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <button type="button" id="add-item" className="btn btn-soft-secondary fw-medium" onClick={addOptionValue}>
                        <i className="ri-add-fill me-1 align-bottom"></i>
                      </button>
                    </div>
                    <div className="tab-pane" id="addons" role="tabpanel">
                      {formData.addons?.map((val: IAddon, x: number) => (
                        <div key={x} className="border-bottom mb-4 pb-3">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label" htmlFor={'addonNameEn' + x}>
                                  {t('Form.NameEn')}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={'addonNameEn' + x}
                                  placeholder={t('Form.NameEn')}
                                  name="addonNameEn"
                                  value={formData.addons[x].name.En}
                                  onChange={(e) => changeHandler(e, x)}
                                />
                                <div className="invalid-feedback">{t('Validation.Name')}</div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label" htmlFor={'addonNameAr' + x}>
                                  {t('Form.NameAr')}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={'addonNameAr' + x}
                                  placeholder={t('Form.NameAr')}
                                  name="addonNameAr"
                                  value={formData.addons[x].name.Ar}
                                  onChange={(e) => changeHandler(e, x)}
                                />
                                <div className="invalid-feedback">{t('Validation.Name')}</div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label className="form-label" htmlFor={'addonOptional' + x}>
                                {t('Common.IsOptional')}
                              </label>
                              <div className="mt-4 mt-lg-0">
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={'isOptional' + x}
                                      id={'optional' + x}
                                      value="true"
                                      checked={formData.addons[x].isOptional}
                                      onChange={(e) => changeHandler(e, x)}
                                    />
                                    {t('Common.Optional')}
                                  </label>
                                </div>

                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={'isOptional' + x}
                                      value="false"
                                      checked={!formData.addons[x].isOptional}
                                      onChange={(e) => changeHandler(e, x)}
                                    />
                                    {t('Common.Required')}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label" htmlFor={'addonPrice' + x}>
                                  {t('Common.Price')}
                                </label>
                                <div className="input-group has-validation mb-3">
                                  <span className="input-group-text" id={'addonPrice' + x}>
                                    {t('Common.EGP')}
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={'addonPrice' + x}
                                    placeholder={t('Common.Price')}
                                    name="addonPrice"
                                    value={formData.addons[x].price}
                                    onChange={(e) => changeHandler(e, x)}
                                  />
                                  <div className="invalid-feedback">{t('Validation.Price')}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <button type="button" id="add-item" className="btn btn-soft-secondary fw-medium" onClick={addProductAddon}>
                        <i className="ri-add-fill me-1 align-bottom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="card">
              <div className="card-header">
                <ul className="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#metadata" role="tab">
                      {t('Common.MetaData')}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="card-body">
                <div className="tab-content">
                  <div className="tab-pane active" id="metadata" role="tabpanel">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="metaTitle">
                            {t('Common.MetaTitle')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('Common.MetaTitle')}
                            id="metaTitle"
                            name="metaTitle"
                            value={formData.metadata?.title}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="metaKeywords">
                            {t('Common.MetaKeywords')}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('Common.MetaKeywords')}
                            id="metaKeywords"
                            name="metaKeywords"
                            value={formData.metadata?.keywords}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className="form-label" htmlFor="metaDescription">
                        {t('Common.MetaDescription')}
                      </label>
                      <textarea
                        className="form-control"
                        id="metaDescription"
                        placeholder={t('Common.MetaDescription')}
                        rows={3}
                        value={formData.metadata?.description}
                        name="metaDescription"
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">{t('Common.ProductGallery')}</h5>
              </div>
              <div className="card-body">
                <DropzoneComponent uploadedFiles={gallaryUploadHandler} images={formData.gallary}></DropzoneComponent>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">{t('Common.MainImage')}</h5>
              </div>
              <div className="card-body">
                <div className="mb-4">
                  <div className="text-center">
                    <div className="position-relative d-inline-block">
                      <div className="position-absolute top-100 start-100 translate-middle">
                        <label htmlFor="logo-image" className="mb-0" data-bs-toggle="tooltip" data-bs-placement="right" title="Select Logo">
                          <div className="avatar-xs">
                            <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                              <i className="ri-image-fill"></i>
                            </div>
                          </div>
                        </label>
                        <input
                          className="form-control d-none"
                          id="logo-image"
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          onChange={mainUploadHandler}
                        />
                      </div>
                      <div className="avatar-lg">
                        <div className="avatar-title bg-light rounded">
                          <img
                            className="avatar-xl h-auto"
                            src={mainPreview}
                            alt="Product-Image"
                            onLoad={() => {
                              URL.revokeObjectURL(mainPreview)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">{t('Common.Category')}</h5>
              </div>
              <div className="card-body">
                <select className="form-select" id="category" value={formData.category?._id} name="category" onChange={changeHandler}>
                  {categories?.map((category: ICategory) => (
                    <option key={category._id} value={category._id}>
                      {Translation(category.name)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <div className="text-end mb-3">
                  <button type="button" className="btn btn-success w-sm" onClick={submitHandler}>
                    {t('Actions.Update')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-m-3"></div>
        </div>
      </form>
    </div>
  )
}

export default ProductEditPage
