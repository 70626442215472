import React from 'react'
import HeaderComponent from './Header.component'
import FooterComponent from './Footer.component'
import SidemenuComponent from './Sidemenu.component'
import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useLoading } from '../provider/IsLoading.provider'

const LayoutComponent: React.FC<any> = () => {
  const { loading } = useLoading()

  return (
    <div id="layout-wrapper">
      <Helmet>
        <script src="/assets/js/pages/layout.js"></script>
      </Helmet>
      <HeaderComponent />

      <SidemenuComponent />

      <div className="vertical-overlay"></div>

      <div className="main-content">
        <div className="page-content">
          {loading && (
            <div id="preloader">
              <div id="status">
                <div className="spinner-border text-primary avatar-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          )}
          <Outlet />
        </div>
        <FooterComponent />
      </div>
    </div>
  )
}

export default LayoutComponent
