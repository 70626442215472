import { _ } from 'gridjs-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component'
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface'
import GridjsComponent from '../../../components/gridjs/Gridjs.component'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import { IOrder } from '../interfaces/order.interface'
import { listSellerOrdersAction } from '../actions/orders.actions'
import { useLoading } from '../../../provider/IsLoading.provider'
import { useNavigate } from 'react-router-dom'

export const OrdersListPage: React.FC<any> = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { orders, status } = useAppSelector((state) => state.order)
  const { setLoading } = useLoading()
  const navigate = useNavigate()
  const [totalEarning, setTotalEarning] = useState(0)
  const [remainingBalance, setRemainingBalance] = useState(0)
  const [paidBalance, setPaidBalance] = useState(0)
  const breadcrumb: Breadcrumb = {
    title: t('Common.Orders'),
    links: [
      { name: t('Common.Dashboard'), path: '/' },
      { name: t('Common.Orders'), path: '/orders/list' }
    ]
  }

  const order_columns = [
    {
      name: 'ID',
      width: '250px',
      sort: true,
      data: (e: IOrder) => {
        return _(
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <p className="text-muted mb-0">
                <a role="button" onClick={() => navigateToDetails('/orders/details/' + e._id)} className="text-body">
                  {e._id}
                </a>
              </p>
            </div>
          </div>
        )
      }
    },
    {
      name: t('Common.OrderType'),
      sort: true,
      width: '100px',
      data: (e: IOrder) => {
        return _(e.specialOrder ? t('Common.SpecialOrder') : t('Common.NormalOrder'))
      }
    },
    {
      name: t('Common.IsActive'),
      sort: true,
      width: '100px',
      data: (e: IOrder) => {
        return _(
          e.isActive ? (
            <span className="badge bg-success-subtle text-success text-uppercase">{t('Common.Active')}</span>
          ) : (
            <span className="badge bg-danger-subtle text-danger text-uppercase">{t('Common.InActive')}</span>
          )
        )
      }
    },
    {
      name: t('Common.Status'),
      sort: true,
      width: '100px',
      data: (e: IOrder) => {
        return _(e.status)
      }
    },
    {
      name: t('Actions.Actions'),
      width: '110px',
      data: (e: IOrder) => {
        return _(
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
              <a role="button" onClick={() => navigateToDetails('/orders/details/' + e._id)} className="text-body">
                <i className="ri-eye-fill fs-16"></i>
              </a>
            </li>
          </ul>
        )
      }
    }
  ]

  const navigateToDetails = (navLink: string) => {
    navigate(navLink)
  }

  useEffect(() => {
    if (status == REDUX_STATUS.SUCCEEDED || status == REDUX_STATUS.FAILED) {
      setLoading(false)
    }
    calcTotalOrders(orders)
    calcRemainingBalance(orders)
    calcPaidBalance(orders)
  }, [orders, setLoading, status])

  const calcTotalOrders = (orders: IOrder[]) => {
    let total = 0
    orders.map((order: IOrder) => {
      total += order.paymentInfo.totalPrice
      setTotalEarning(total)
    })
  }

  const calcRemainingBalance = (orders: IOrder[]) => {
    let remaining = 0
    orders.map((order: IOrder) => {
      remaining += order.paymentInfo.remainingAmount
      setRemainingBalance(remaining)
    })
  }

  const calcPaidBalance = (orders: IOrder[]) => {
    let paid = 0
    orders.map((order: IOrder) => {
      paid += order.paymentInfo.paidAmount
      setPaidBalance(paid)
    })
  }

  useEffect(() => {
    if (status == REDUX_STATUS.IDLE) {
      setLoading(true)
      dispatch(
        listSellerOrdersAction({
          page: 1,
          perPage: 12,
          orderBy: 'createdAt',
          orderDirection: SORT_DIRECTION.ASC
        })
      )
    }
  }, [dispatch, status, setLoading])

  return (
    <div className="container-fluid">
      <div className="row">
        <BreadcrumbComponent breadcrumb={breadcrumb} />
      </div>

      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0"> Total Earnings</p>
                </div>
                <div className="flex-shrink-0">
                  <span className="avatar-title bg-success-subtle rounded fs-3">
                    <i className="bx bx-dollar-circle text-success"></i>
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary">
                    <span className="counter-value" data-target={totalEarning}>
                      {totalEarning} {t('Common.CurrencyCode')}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Common.Orders')}</p>
                </div>
                <div className="flex-shrink-0">
                  <span className="avatar-title bg-info-subtle rounded fs-3">
                    <i className="bx bx-shopping-bag text-info"></i>
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary">
                    <span className="counter-value" data-target={orders.length}>
                      {orders.length} {t('Common.Orders')}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Paid Balance</p>
                </div>
                <div className="flex-shrink-0">
                  <span className="avatar-title bg-warning-subtle rounded fs-3">
                    <i className="bx bx-user-circle text-warning"></i>
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary">
                    <span className="counter-value" data-target="183.35">
                      {paidBalance} {t('Common.CurrencyCode')}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card card-animate">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0"> Remaining Balance</p>
                </div>
                <div className="flex-shrink-0">
                  <span className="avatar-title bg-primary-subtle rounded fs-3">
                    <i className="bx bx-wallet text-primary"></i>
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary">
                    <span className="counter-value" data-target={remainingBalance}>
                      {remainingBalance} {t('Common.CurrencyCode')}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div>
            <div className="card">
              <div className="card-header border-0">
                <div className="row g-4">
                  <div className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div className="search-box ms-2">
                        <input type="text" className="form-control bg-light border-0" id="searchProductList" placeholder="Search Orders..." />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="tab-content text-muted">
                  <div id="table-product-list-all" className="table-card gridjs-border-none">
                    <GridjsComponent columns={order_columns} data={orders} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
