import React from 'react'

const ViewModalComponent: React.FC<any> = (props) => {
  return (
    <div id="viewModal" className="modal fade" tabIndex={-1} aria-labelledby="viewModalLabel" aria-hidden="true" style={{ display: 'none' }}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div className="modal-body">
            {props.body}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewModalComponent
