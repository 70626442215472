import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  addSellerKitchen,
  getAdminKitchen,
  getAllKitchen,
  getSellerKitchen,
  updateSellerKitchen,
  uploadGallaryService,
  uploadImageService
} from '../services/kitchen.service'

// Admin Actions
export const getAdminKitchensAction = createAsyncThunk('getAdminKitchensAction/get', async (data: any, thunkApi) => {
  try {
    const response = await getAllKitchen(data)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getAdminKitchenAction = createAsyncThunk('getAdminKitchenById/get', async (id: string, thunkApi) => {
  try {
    const response = await getAdminKitchen(id)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

// Seller Actions
export const getSellerKitchenAction = createAsyncThunk('getKitchenById/get', async (_, thunkApi) => {
  try {
    const response = await getSellerKitchen()
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const updateKitchenAction = createAsyncThunk('updateKitchen/put', async (data: { id: string; formData: any }, thunkApi) => {
  try {
    const response = await updateSellerKitchen(data.id, data.formData)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const addKitchenAction = createAsyncThunk('addkitchen/post', async (data: any, thunkApi) => {
  try {
    const response = await addSellerKitchen(data)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const uploadImageAction = createAsyncThunk('uploadImageAction/post', async (data: { id: string; image: any }, thunkApi) => {
  try {
    const response = await uploadImageService(data)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const UploadGallaryAction = createAsyncThunk('UploadGallaryAction/post', async (data: { id: string; image: any }, thunkApi) => {
  try {
    const response = await uploadGallaryService(data)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})
