import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'default';

interface DataReducer {
	toastObj: {
		show: boolean,
		type: TypeOptions,
		message: string
	}
}

const initialState: DataReducer = {
	toastObj: {
		show: false,
		type: 'info',
		message: ''
	}
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setToast(state: DataReducer, action: PayloadAction<any>) {
      state.toastObj = action.payload
    }
  }
})

export const layoutActions = layoutSlice.actions
export default layoutSlice.reducer
