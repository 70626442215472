import React from "react";

const FooterComponent: React.FC<any> = () => {
  return(
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6">
            {new Date().getFullYear()} © Akla Beity.
          </div>
          <div className="col-sm-6">
            <div className="text-sm-end d-none d-sm-block">All copy rights reserved © Akla Beity</div>
          </div>
        </div>
      </div>
    </footer>
    )
}

export default FooterComponent;