import React, { useEffect, useRef } from 'react'

declare global {
  interface Window {
    initMap: () => void
  }
}

interface GoogleMapsProps {
  location: {
    type: string
    coordinates: Array<number>
  }
  setUserLocation: (e: { type: string; coordinates: Array<number> }) => any
}

export const GoogleMapsComponent: React.FC<GoogleMapsProps> = ({ setUserLocation, location }) => {
  const mapRef = useRef(null)

  useEffect(() => {
    const initMap = async (): Promise<void> => {
      const position = { lat: location?.coordinates[0], lng: location?.coordinates[1] }

      const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary

      const map = new Map(document.getElementById('map') as HTMLElement, {
        zoom: 16,
        center: position,
        mapId: 'DEMO_MAP_ID'
      })

      const marker = new google.maps.Marker({
        position: position,
        map,
        title: 'Click to zoom'
      })

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }

          if (!location) {
            marker.setPosition(pos)
            setUserLocation({
              type: 'Point',
              coordinates: [pos.lat, pos.lng]
            })
            map.setCenter(pos)
          }
        })
      }

      map.addListener('click', (mapsMouseEvent: { latLng: google.maps.LatLng }) => {
        map.panTo(mapsMouseEvent.latLng as google.maps.LatLng)
        marker.setPosition(mapsMouseEvent.latLng)
        setUserLocation({
          type: 'Point',
          coordinates: [mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng()]
        })
      })
    }

    initMap()
  }, [location, setUserLocation])

  return <div id="map" ref={mapRef} style={{ height: '70vh' }}></div>
}
