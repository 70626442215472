import { createSlice } from '@reduxjs/toolkit'
import { IKitchen } from '../interface/kitchen.interface'
import { addKitchenAction, getAdminKitchenAction, getAdminKitchensAction, getSellerKitchenAction, updateKitchenAction } from '../actions/kitchen.actions'
import { ErrorResponse } from '../../../utility/interfaces/ErrorResponse.interface'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'

export const SuccessAlert = withReactContent(Swal)

interface DataReducer {
  kitchens: IKitchen[]
  kitchen: IKitchen
  status: string
  kitchenStatus: string
  error: ErrorResponse | null
}

const initialState: DataReducer = {
  kitchens: [],
  kitchen: {} as IKitchen,
  status: 'idle',
  kitchenStatus: 'idle',
  error: null
}

const kitchenSlice = createSlice({
  name: 'kitchen',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdminKitchensAction.pending, (state) => {
        state.error = null
        state.status = REDUX_STATUS.PENDING
      })
      .addCase(getAdminKitchensAction.fulfilled, (state, action) => {
        state.status = REDUX_STATUS.SUCCEEDED
        state.error = null
        state.kitchens = action.payload.data
      })
      .addCase(getAdminKitchensAction.rejected, (state, action: any) => {
        state.status = REDUX_STATUS.FAILED
        state.error = action.payload
      })

      .addCase(getAdminKitchenAction.pending, (state) => {
        state.error = null
        state.kitchenStatus = REDUX_STATUS.PENDING
      })
      .addCase(getAdminKitchenAction.fulfilled, (state, action) => {
        state.kitchenStatus = REDUX_STATUS.SUCCEEDED
        state.error = null
        state.kitchen = action.payload.data
      })
      .addCase(getAdminKitchenAction.rejected, (state, action: any) => {
        state.kitchenStatus = REDUX_STATUS.FAILED
        state.error = action.payload
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })

      .addCase(getSellerKitchenAction.pending, (state) => {
        state.error = null
        state.kitchenStatus = REDUX_STATUS.PENDING
      })
      .addCase(getSellerKitchenAction.fulfilled, (state, action) => {
        state.kitchenStatus = REDUX_STATUS.SUCCEEDED
        localStorage.setItem('KITCHEN', JSON.stringify(action.payload.data));
        state.error = null
        state.kitchen = action.payload.data
      })
      .addCase(getSellerKitchenAction.rejected, (state, action: any) => {
        state.kitchenStatus = REDUX_STATUS.FAILED
        state.error = action.payload
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })

      .addCase(addKitchenAction.pending, (state) => {
        state.error = null
        state.status = 'pending'
      })
      .addCase(addKitchenAction.fulfilled, (state) => {
        state.status = 'success'
        state.error = null
      })
      .addCase(addKitchenAction.rejected, (state, action: any) => {
        state.status = 'faild'
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
        state.error = action.payload
      })

      .addCase(updateKitchenAction.pending, (state) => {
        state.error = null
        state.status = 'pending'
      })
      .addCase(updateKitchenAction.fulfilled, (state) => {
        state.status = 'success'
				SuccessAlert.fire({ position: 'center', icon: 'success', title: 'Kitchen modified successfully', showConfirmButton: !0, timer: 5000 })
        state.error = null
      })
      .addCase(updateKitchenAction.rejected, (state, action: any) => {
        state.status = 'faild'
				SuccessAlert.fire({ position: 'center', icon: 'error', title: 'Error happened!', showConfirmButton: !0, timer: 5000 })
        state.error = action.payload
      })
  }
})

export const kitchenActions = kitchenSlice.actions
export default kitchenSlice.reducer
