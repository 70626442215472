import React, { useContext, useEffect, useState } from 'react'
import { useAppDispatch } from '../store/store'
import { authActions } from '../modules/auth/slice/auth.slice'
import { profileActions } from '../modules/profile/slice/profile.slice'
import { NavLink, useNavigate } from 'react-router-dom'
import ProfileContext from '../provider/Profile.provider'
import { ROUTES } from '../resources/routes-constants'
import { useTranslation } from 'react-i18next'

const HeaderComponent: React.FC<any> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const userProfile = useContext(ProfileContext)
  const [langIcon, setLangIcon] = useState('/assets/images/flags/us.svg')

  const icons = {
    ar: '/assets/images/flags/ae.svg',
    en: '/assets/images/flags/us.svg'
  }

  const language = window.localStorage.getItem('language')!

  useEffect(() => {
    if (language == 'en') {
      setLangIcon(icons.en)
      i18n.changeLanguage('en')
    } else {
      setLangIcon(icons.ar)
      i18n.changeLanguage('ar')
    }
    window.document.getElementsByTagName('html')[0].setAttribute('lang', language)
  }, [language, icons.ar, icons.en, i18n])

  const logoutHandler = () => {
    localStorage.removeItem('TK')
    localStorage.removeItem('PROFILE')
    localStorage.removeItem('KITCHEN')
    dispatch(authActions.setAuth({}))
    dispatch(profileActions.setProfile({}))
    navigate('/auth/login')
  }

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    window.localStorage.setItem('language', lang)
  }

  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box horizontal-logo">
              <a href="index.html" className="logo logo-light">
                <span className="logo-sm">
                  <img src="/assets/images/logo.png" alt="Akla Beity" />
                </span>
                <span className="logo-lg">
                  <img src="/assets/images/logo.png" alt="Akla Beity" />
                </span>
              </a>
            </div>

            <button type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
              <span className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>

          <div className="d-flex align-items-center">
            <div className="dropdown ms-1 topbar-head-dropdown header-item">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <img id="header-lang-img" src={langIcon} alt="Header Language" height="20" className="rounded" />
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <button
                  className="btn-link dropdown-item notify-item language py-2"
                  data-lang="en"
                  title="English"
                  onClick={() => changeLanguage('en')}>
                  <img src="/assets/images/flags/us.svg" alt="user-image" className="me-2 rounded" height="18" />
                  <span className="align-middle">English</span>
                </button>

                <button className="btn-link dropdown-item notify-item language" data-lang="ar" title="Arabic" onClick={() => changeLanguage('ar')}>
                  <img src="/assets/images/flags/ae.svg" alt="user-image" className="me-2 rounded" height="18" />
                  <span className="align-middle">عربى</span>
                </button>
              </div>
            </div>

            <div className="dropdown topbar-head-dropdown ms-1 header-item" id="notificationDropdown">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-haspopup="true"
                aria-expanded="false">
                <i className="bx bx-bell fs-22"></i>
                <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                  3<span className="visually-hidden">unread messages</span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
                <div className="dropdown-head bg-primary bg-pattern rounded-top">
                  <div className="p-3">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                      </div>
                      <div className="col-auto dropdown-tabs">
                        <span className="badge bg-light-subtle text-body fs-13"> 4 New</span>
                      </div>
                    </div>
                  </div>

                  <div className="px-2 pt-2">
                    <ul className="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true" id="notificationItemsTab" role="tablist">
                      <li className="nav-item waves-effect waves-light">
                        <a className="nav-link active" data-bs-toggle="tab" href="index.html#all-noti-tab" role="tab" aria-selected="true">
                          All (4)
                        </a>
                      </li>
                      <li className="nav-item waves-effect waves-light">
                        <a className="nav-link" data-bs-toggle="tab" href="index.html#messages-tab" role="tab" aria-selected="false">
                          Messages
                        </a>
                      </li>
                      <li className="nav-item waves-effect waves-light">
                        <a className="nav-link" data-bs-toggle="tab" href="index.html#alerts-tab" role="tab" aria-selected="false">
                          Alerts
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="tab-content position-relative" id="notificationItemsTabContent">
                  <div className="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                    <div data-simplebar style={{ maxHeight: '300px' }} className="pe-2">
                      <div className="text-reset notification-item d-block dropdown-item position-relative">
                        <div className="d-flex">
                          <div className="avatar-xs me-3 flex-shrink-0">
                            <span className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                              <i className="bx bx-badge-check"></i>
                            </span>
                          </div>
                          <div className="flex-grow-1">
                            <a href="index.html#!" className="stretched-link">
                              <h6 className="mt-0 mb-2 lh-base">
                                Your <b>Elite</b> author Graphic Optimization <span className="text-secondary">reward</span> is ready!
                              </h6>
                            </a>
                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span>
                                <i className="mdi mdi-clock-outline"></i> Just 30 sec ago
                              </span>
                            </p>
                          </div>
                          <div className="px-2 fs-15">
                            <div className="form-check notification-check">
                              <input className="form-check-input" type="checkbox" value="" id="all-notification-check01" />
                              <label className="form-check-label" htmlFor="all-notification-check01"></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-reset notification-item d-block dropdown-item position-relative">
                        <div className="d-flex">
                          <img src="/assets/images/users/avatar-2.jpg" className="me-3 rounded-circle avatar-xs flex-shrink-0" alt="user-pic" />
                          <div className="flex-grow-1">
                            <a href="index.html#!" className="stretched-link">
                              <h6 className="mt-0 mb-1 fs-13 fw-semibold">Angela Bernier</h6>
                            </a>
                            <div className="fs-13 text-muted">
                              <p className="mb-1">Answered to your comment on the cash flow forecasts graph 🔔.</p>
                            </div>
                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span>
                                <i className="mdi mdi-clock-outline"></i> 48 min ago
                              </span>
                            </p>
                          </div>
                          <div className="px-2 fs-15">
                            <div className="form-check notification-check">
                              <input className="form-check-input" type="checkbox" value="" id="all-notification-check02" />
                              <label className="form-check-label" htmlFor="all-notification-check02"></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-reset notification-item d-block dropdown-item position-relative">
                        <div className="d-flex">
                          <div className="avatar-xs me-3 flex-shrink-0">
                            <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-16">
                              <i className="bx bx-message-square-dots"></i>
                            </span>
                          </div>
                          <div className="flex-grow-1">
                            <a href="index.html#!" className="stretched-link">
                              <h6 className="mt-0 mb-2 fs-13 lh-base">
                                You have received <b className="text-success">20</b> new messages in the conversation
                              </h6>
                            </a>
                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span>
                                <i className="mdi mdi-clock-outline"></i> 2 hrs ago
                              </span>
                            </p>
                          </div>
                          <div className="px-2 fs-15">
                            <div className="form-check notification-check">
                              <input className="form-check-input" type="checkbox" value="" id="all-notification-check03" />
                              <label className="form-check-label" htmlFor="all-notification-check03"></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-reset notification-item d-block dropdown-item position-relative">
                        <div className="d-flex">
                          <img src="/assets/images/users/avatar-8.jpg" className="me-3 rounded-circle avatar-xs flex-shrink-0" alt="user-pic" />
                          <div className="flex-grow-1">
                            <a href="index.html#!" className="stretched-link">
                              <h6 className="mt-0 mb-1 fs-13 fw-semibold">Maureen Gibson</h6>
                            </a>
                            <div className="fs-13 text-muted">
                              <p className="mb-1">We talked about a project on linkedin.</p>
                            </div>
                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span>
                                <i className="mdi mdi-clock-outline"></i> 4 hrs ago
                              </span>
                            </p>
                          </div>
                          <div className="px-2 fs-15">
                            <div className="form-check notification-check">
                              <input className="form-check-input" type="checkbox" value="" id="all-notification-check04" />
                              <label className="form-check-label" htmlFor="all-notification-check04"></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="my-3 text-center view-all">
                        <button type="button" className="btn btn-soft-success waves-effect waves-light">
                          View All Notifications <i className="ri-arrow-right-line align-middle"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade py-2 ps-2" id="messages-tab" role="tabpanel" aria-labelledby="messages-tab">
                    <div data-simplebar style={{ maxHeight: '300px' }} className="pe-2">
                      <div className="text-reset notification-item d-block dropdown-item">
                        <div className="d-flex">
                          <img src="/assets/images/users/avatar-3.jpg" className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                          <div className="flex-grow-1">
                            <a href="index.html#!" className="stretched-link">
                              <h6 className="mt-0 mb-1 fs-13 fw-semibold">James Lemire</h6>
                            </a>
                            <div className="fs-13 text-muted">
                              <p className="mb-1">We talked about a project on linkedin.</p>
                            </div>
                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span>
                                <i className="mdi mdi-clock-outline"></i> 30 min ago
                              </span>
                            </p>
                          </div>
                          <div className="px-2 fs-15">
                            <div className="form-check notification-check">
                              <input className="form-check-input" type="checkbox" value="" id="messages-notification-check01" />
                              <label className="form-check-label" htmlFor="messages-notification-check01"></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-reset notification-item d-block dropdown-item">
                        <div className="d-flex">
                          <img src="/assets/images/users/avatar-2.jpg" className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                          <div className="flex-grow-1">
                            <a href="index.html#!" className="stretched-link">
                              <h6 className="mt-0 mb-1 fs-13 fw-semibold">Angela Bernier</h6>
                            </a>
                            <div className="fs-13 text-muted">
                              <p className="mb-1">Answered to your comment on the cash flow forecasts graph 🔔.</p>
                            </div>
                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span>
                                <i className="mdi mdi-clock-outline"></i> 2 hrs ago
                              </span>
                            </p>
                          </div>
                          <div className="px-2 fs-15">
                            <div className="form-check notification-check">
                              <input className="form-check-input" type="checkbox" value="" id="messages-notification-check02" />
                              <label className="form-check-label" htmlFor="messages-notification-check02"></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-reset notification-item d-block dropdown-item">
                        <div className="d-flex">
                          <img src="/assets/images/users/avatar-6.jpg" className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                          <div className="flex-grow-1">
                            <a href="index.html#!" className="stretched-link">
                              <h6 className="mt-0 mb-1 fs-13 fw-semibold">Kenneth Brown</h6>
                            </a>
                            <div className="fs-13 text-muted">
                              <p className="mb-1">Mentionned you in his comment on 📃 invoice #12501.</p>
                            </div>
                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span>
                                <i className="mdi mdi-clock-outline"></i> 10 hrs ago
                              </span>
                            </p>
                          </div>
                          <div className="px-2 fs-15">
                            <div className="form-check notification-check">
                              <input className="form-check-input" type="checkbox" value="" id="messages-notification-check03" />
                              <label className="form-check-label" htmlFor="messages-notification-check03"></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-reset notification-item d-block dropdown-item">
                        <div className="d-flex">
                          <img src="/assets/images/users/avatar-8.jpg" className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                          <div className="flex-grow-1">
                            <a href="index.html#!" className="stretched-link">
                              <h6 className="mt-0 mb-1 fs-13 fw-semibold">Maureen Gibson</h6>
                            </a>
                            <div className="fs-13 text-muted">
                              <p className="mb-1">We talked about a project on linkedin.</p>
                            </div>
                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span>
                                <i className="mdi mdi-clock-outline"></i> 3 days ago
                              </span>
                            </p>
                          </div>
                          <div className="px-2 fs-15">
                            <div className="form-check notification-check">
                              <input className="form-check-input" type="checkbox" value="" id="messages-notification-check04" />
                              <label className="form-check-label" htmlFor="messages-notification-check04"></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="my-3 text-center view-all">
                        <button type="button" className="btn btn-soft-success waves-effect waves-light">
                          View All Messages <i className="ri-arrow-right-line align-middle"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab"></div>

                  <div className="notification-actions" id="notification-actions">
                    <div className="d-flex text-muted justify-content-center">
                      Select
                      <div id="select-content" className="text-body fw-semibold px-1">
                        0
                      </div>
                      Result
                      <button type="button" className="btn btn-link link-danger p-0 ms-3" data-bs-toggle="modal" data-bs-target="#removeNotificationModal">
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle header-profile-user"
                    src={userProfile.photo ? userProfile.photo : 'public/assets/images/users/user-dummy-img.jpg'}
                    alt="Header Avatar"
                  />
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userProfile.firstName + ' ' + userProfile.lastName}</span>
                    <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">{userProfile.role?.name}</span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">Welcome {userProfile.firstName}!</h6>
                <NavLink className="dropdown-item" to={ROUTES.PROFILE_EDIT}>
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span className="align-middle">{t('Common.Profile')}</span>
                </NavLink>
                <a className="dropdown-item" href="pages-faqs.html">
                  <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Help</span>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="pages-profile.html">
                  <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">
                    Balance : <b>$5971.67</b>
                  </span>
                </a>
                <a className="dropdown-item" href="pages-profile-settings.html">
                  <span className="badge bg-success-subtle text-success mt-1 float-end">New</span>
                  <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Settings</span>
                </a>
                <button className="dropdown-item" onClick={logoutHandler}>
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderComponent
