import { createSlice } from '@reduxjs/toolkit'
import { IOffer } from '../interface/offer.interface'
import { addOfferAction, deleteOfferAction, getOfferByIdAction, editOfferAction, getOffersAction } from '../actions/offer.actions'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const SuccessAlert = withReactContent(Swal)

interface DataReducer {
  offers: IOffer[]
  status: REDUX_STATUS
}

const initialState: DataReducer = {
  offers: [],
  status: REDUX_STATUS.IDLE
}

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOffersAction.pending, (state) => {
        state.status = REDUX_STATUS.PENDING
      })
      .addCase(getOffersAction.fulfilled, (state, action) => {
        state.status = REDUX_STATUS.SUCCEEDED
        state.offers = action.payload.data
      })
      .addCase(getOffersAction.rejected, (state, action: any) => {
        state.status = REDUX_STATUS.FAILED
        SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })

      .addCase(addOfferAction.pending, () => {})
      .addCase(addOfferAction.fulfilled, (_, action) => {
        SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
      .addCase(addOfferAction.rejected, (_, action: any) => {
        SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })

      .addCase(deleteOfferAction.pending, () => {})
      .addCase(deleteOfferAction.fulfilled, (_, action) => {
        SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
      .addCase(deleteOfferAction.rejected, (_, action: any) => {
        SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })

      .addCase(getOfferByIdAction.pending, () => {})
      .addCase(getOfferByIdAction.fulfilled, () => {})
      .addCase(getOfferByIdAction.rejected, (_, action: any) => {
        SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })

      .addCase(editOfferAction.pending, () => {})
      .addCase(editOfferAction.fulfilled, (_, action) => {
        SuccessAlert.fire({ position: 'center', icon: 'success', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
      .addCase(editOfferAction.rejected, (_, action: any) => {
        SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, showConfirmButton: !0, timer: 5000 })
      })
  }
})

export const offerActions = offerSlice.actions
export default offerSlice.reducer
