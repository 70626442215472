import { Api } from "../../../resources/api-constants";
import { IAttribute } from "../interface/attribute.interface";
import CustomAxios from '../../../utility/customAxios';

export const getAdminAttributes = (query: any) => {
	return CustomAxios.get(`${Api.All_ADMIN_ATTRIBUTES}?offset=${query.offset}&perPage=${query.limit}&orderDirection=asc`);
};

export const getAttributeById = (id: string) => {
	return CustomAxios.get(`${Api.GET_ATTRIBUTE}/${id}`);
};

export const addAttribute = (data: IAttribute) => {
	return CustomAxios.post(`${Api.ADD_ATTRIBUTE}`, data);
};

export const editAttribute = (id: string, data: IAttribute) => {
	return CustomAxios.put(`${Api.EDIT_ATTRIBUTE}/${id}`, data);
};

export const deleteAttribute = (id: string) => {
	return CustomAxios.delete(`${Api.DELETE_ATTRIBUTE}/${id}`);
};
