import React, { useState } from 'react'
interface TagsInputProps {
  initialValues: Array<string>
  name: string
  tagChangeHandler: any
}
const TagsInput: React.FC<TagsInputProps> = ({ initialValues, name, tagChangeHandler }) => {
  const [tags, setTags] = useState(initialValues as Array<string>)

  const handleKeyDown = async (e: any) => {
    if (e.key !== 'Enter') return
    const value = e.target.value
    if (!value.trim()) return
    await setTags([...tags, value])
    tagChangeHandler({ target: { name, value: [...tags, value] } })
    e.target.value = ''
  }

  const removeTag = (index: number) => {
    setTags(tags.filter((el, i) => i !== index))
  }

  return (
    <div className="tags-input-container form-control">
      {tags?.map((tag, index) => (
        <div className="tag-item" key={index}>
          <span className="text">{tag}</span>
          <span className="close" onClick={() => removeTag(index)}>
            &times;
          </span>
        </div>
      ))}
      <input onKeyDown={handleKeyDown} type="text" className="tags-input" placeholder="Type somthing" />
    </div>
  )
}

export default TagsInput
