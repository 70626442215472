import { createAsyncThunk } from '@reduxjs/toolkit'
import { uploadImageService, getProfileService, updateProfileService, updatePasswordService } from '../profile.service'

export const uploadImageAction = createAsyncThunk('uploadImageAction/post', async (data: any, thunkApi) => {
	try {
		const response = await uploadImageService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const getProfileAction = createAsyncThunk('getProfileAction/post', async (_, thunkApi) => {
	try {
		const response = await getProfileService()
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const updateProfileAction = createAsyncThunk('updateProfileAction/patch', async (data: any, thunkApi) => {
	try {
		const response = await updateProfileService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const updatePasswordAction = createAsyncThunk('updatePasswordAction/patch', async (data: any, thunkApi) => {
	try {
		const response = await updatePasswordService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})
