import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllCitiesService, getAllGovernoratesService } from '../services/lookup.service'

export const getAllGovernoratesAction = createAsyncThunk('getAllGovernoratesAction/get', async (_, thunkApi) => {
	try {
		const response = await getAllGovernoratesService()
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const getAllCitiesAction = createAsyncThunk('getAllCitiesAction/get', async (id:string, thunkApi) => {
	try {
		const response = await getAllCitiesService(id)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})
