import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import RoleContext from '../provider/Profile.provider';
import { ROLE } from '../utility/enums/role.enum';

const RoleGuard = (props: { children: any }) => {
	const userProfile = useContext(RoleContext);

	if (userProfile.role.type != ROLE.ADMIN) {
		return <Navigate to="/" replace />;
	} else {
		return props.children;
	}
};

export default RoleGuard;
