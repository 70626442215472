import { ISpecialOffer } from './../interfaces/special-order.interface';
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IPagination } from '../../../utility/interfaces/pagination.interface'
import { addSpecialOfferApi, getSpecialOrderDetailsAPi, listSellerSpecialOrdersApi } from '../services/special-order.service'

// Seller Actions
export const listSellerSpecialOrdersAction = createAsyncThunk('listSellerSpecialOrdersAction/get', async (data: IPagination, thunkApi) => {
  try {
    const response = await listSellerSpecialOrdersApi(data)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getSpecialOrderDetailsAction = createAsyncThunk('getSpecialOrderDetailsAction/get', async (id: string, thunkApi) => {
  try {
    const response = await getSpecialOrderDetailsAPi(id)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const addSpecialOfferAction = createAsyncThunk('addSpecialOfferAction/put', async (body: {id: string, data: ISpecialOffer}, thunkApi) => {
  try {
    const response = await addSpecialOfferApi(body.id, body.data)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})
