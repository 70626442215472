import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAdminAttributes, getAttributeById, addAttribute, editAttribute, deleteAttribute } from '../services/attribute.service'


export const getAttributesAction = createAsyncThunk('getAttributesAction/get', async (data: any, thunkApi) => {
  try {
    const response = await getAdminAttributes(data)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const getAttributeByIdAction = createAsyncThunk('getAttributeById/get', async (id: string, thunkApi) => {
  try {
    const response = await getAttributeById(id)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const addAttributeAction = createAsyncThunk('addAttribute/post', async (data: any, thunkApi) => {
  try {
    const response = await addAttribute(data)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const editAttributeAction = createAsyncThunk('addAttribute/put', async (data: { id: string; formData: any }, thunkApi) => {
  try {
    const response = await editAttribute(data.id, data.formData)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})

export const deleteAttributeAction = createAsyncThunk('deleteAttribute/post', async (id: string, thunkApi) => {
  try {
    const response = await deleteAttribute(id)
    return response.data
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})
