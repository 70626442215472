import React, { useContext, useEffect, useState } from 'react'
import GridjsComponent from '../../../components/gridjs/Gridjs.component'
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface'
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component'
import WarningComponent from '../../../components/Confirmation-modals/warning.component'
import { html } from 'gridjs'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { deleteSellerProductAction, listAdminProductsAction, listSellerProductsAction } from '../actions/product.actions'
import { ROLE } from '../../../utility/enums/role.enum'
import RoleContext from '../../../provider/Profile.provider'
import { _ } from 'gridjs-react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum'
import { Translation } from '../../../utility/helpers/translation.helper'
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum'
import { IProduct } from '../interface/product.interface'
import { useLoading } from '../../../provider/IsLoading.provider'

const ProductsListPage: React.FC<any> = () => {
	const assets = process.env.REACT_APP_ASSETS_URL
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { products, status } = useAppSelector((state) => state.product)
  const userProfile = useContext(RoleContext)
  const { setLoading } = useLoading()
  const [deleteId, setDeleteId] = useState(null)
  const navigate = useNavigate()
  const breadcrumb: Breadcrumb = {
    title: t('Common.Products'),
    links: [
      { name: t('Common.Dashboard'), path: '/' },
      { name: t('Common.Products'), path: '/products/list' }
    ]
  }
  const columns = [
    {
      name: 'Name',
      width: '250px',
      sort: true,
      data: (e: IProduct) => {
        return _(
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                <img src={assets + e.mainImage} alt={e.name.En} className="img-fluid d-block" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <a role="button" onClick={() => navigateToDetails('/products/view/' + e._id)} className="text-body">
                  {Translation(e.name)}
                </a>
              </h5>
              <p className="text-muted mb-0">
                {t('Common.Category')} : <span className="fw-medium">{Translation(e.category.name)}</span>
              </p>
            </div>
          </div>
        )
      }
    },
    {
      name: t('Common.Category'),
      width: '120px',
      sort: true,
      data: function (e: IProduct) {
        return Translation(e.category.name)
      }
    },
    {
      name: 'isActive',
      sort: true,
      width: '100px',
      data: (e: any) => {
        return html(
          e.isActive
            ? '<span class="badge bg-success-subtle text-success text-uppercase">Active</span>'
            : '<span class="badge bg-danger-subtle text-danger text-uppercase">Inactive</span>'
        )
      }
    },
    {
      name: 'Rating',
      width: '120px',
      sort: true,
      data: function (e: any) {
        return html(`<span class="badge bg-light text-body fs-12 fw-medium"><i class="mdi mdi-star text-warning me-1"></i> ${e.rating} </span></td>`)
      }
    },
    {
      name: 'Action',
      width: '110px',
      data: (e: any) => {
        return _(
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
              <a href={'/products/view/' + e._id} className="text-body">
                <i className="ri-eye-fill fs-16"></i>
              </a>
            </li>
            <li className="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
              <a className="text-primary d-inline-block edit-item-btn" role="button" onClick={() => navigateToDetails('/products/edit/' + e._id)}>
                <i className="ri-pencil-fill fs-16"></i>
              </a>
            </li>
            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Remove">
              <a
                role="button"
                className="text-danger d-inline-block remove-item-btn"
                data-bs-toggle="modal"
                data-bs-target="#warningModal"
                onClick={() => setDeleteId(e._id)}>
                <i className="ri-delete-bin-5-fill fs-16"></i>
              </a>
            </li>
          </ul>
        )
      }
    }
  ]

  const navigateToDetails = (navLink: string) => {
    navigate(navLink)
  }

  useEffect(() => {
    if (status == REDUX_STATUS.SUCCEEDED || status == REDUX_STATUS.FAILED) {
      setLoading(false)
    }
  }, [setLoading, status])

  useEffect(() => {
    if (status == REDUX_STATUS.IDLE) {
      setLoading(true)
      if (userProfile.role.type == ROLE.ADMIN) {
        dispatch(
          listAdminProductsAction({
            page: 1,
            perPage: 12,
            orderBy: 'createdAt',
            orderDirection: SORT_DIRECTION.ASC
          })
        )
      } else {
        dispatch(
          listSellerProductsAction({
            page: 1,
            perPage: 12,
            orderBy: 'createdAt',
            orderDirection: SORT_DIRECTION.ASC
          })
        )
      }
    }
  }, [dispatch, userProfile, status, setLoading])

  const deleteHandler = () => {
    dispatch(deleteSellerProductAction(deleteId!))
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <BreadcrumbComponent breadcrumb={breadcrumb} />
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header border-0">
              <div className="row g-4">
                <div className="col-sm-auto">
                  <div>
                    <Link to={'/products/create'} className="btn btn-success" id="addproduct-btn">
                      <i className="ri-add-line align-bottom me-1"></i> Add Product
                    </Link>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="d-flex justify-content-sm-end">
                    <div className="search-box ms-2">
                      <input type="text" className="form-control bg-light border-0" id="searchProductList" placeholder="Search Products..." />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div id="table-product-list-all" className="table-card gridjs-border-none">
                <GridjsComponent columns={columns} data={products} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <WarningComponent actionHandler={deleteHandler} />
    </div>
  )
}

export default ProductsListPage
