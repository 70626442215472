import { createAsyncThunk } from '@reduxjs/toolkit'
import { IPagination } from '../../../utility/interfaces/pagination.interface';
import { getOrderDetailsApi, listAdminOrdersApi, listSellerOrdersApi , updateOrderStatusApi } from '../services/order.service';

// Admin Actions
export const listAdminOrdersAction = createAsyncThunk('listAdminOrdersAction/get', async (data: IPagination, thunkApi) => {
	try {
		const response = await listAdminOrdersApi(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

// Seller Actions
export const listSellerOrdersAction = createAsyncThunk('listSellerOrdersAction/get', async (data: IPagination, thunkApi) => {
	try {
		const response = await listSellerOrdersApi(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const getOrderDetailsAction = createAsyncThunk('getOrderDetailsAction/get', async (id: string, thunkApi) => {
	try {
		const response = await getOrderDetailsApi(id);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const updateOrderStatusAction = createAsyncThunk('updateOrderStatusAction/put' , async ( data : {id : string , status : string}, thunkApi) => {
	try {
		const response = await updateOrderStatusApi(data);
		return response.data;
	} catch (error : any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})