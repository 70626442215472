const loggerMiddleware = (store: any) => (next: any) => (action: any) => {
	console.group(action.type);
	console.info('dispatching', action);
	const result = next(action);
	console.info('next state', store.getState());
	console.groupEnd();
	return result;
};

export default loggerMiddleware;
