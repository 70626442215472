
import { Api } from '../../../resources/api-constants';
import CustomAxios from '../../../utility/customAxios';
import { PaginationExtractor } from '../../../utility/helpers/pagination-extractor';
import { IKitchen } from '../interface/kitchen.interface';

// Admin Service
export const getAllKitchen = (query: any) => {
	return CustomAxios.get(`${Api.ALL_KITCHENS}${PaginationExtractor(query)}`);
};

export const getAdminKitchen = (id: string) => {
	return CustomAxios.get(`${Api.Get_ADMIN_KITCHEN}/${id}`);
};

// Seller Service
export const getSellerKitchen = () => {
	return CustomAxios.get(`${Api.Get_KITCHEN}`);
};

export const updateSellerKitchen = (id: string, update: IKitchen) => {
	return CustomAxios.put(`${Api.UPDATE_KITCHEN}/${id}`, update);
};

export const addSellerKitchen = (data: IKitchen) => {
	return CustomAxios.post(`${Api.ADD_KITCHEN}`, data);
};

export const uploadImageService = (body: any) => {
	return CustomAxios.post(`${Api.UPLOAD_KITCHEN_IMAGE}/${body.id}`, body.image);
};

export const uploadGallaryService = (body: any) => {
	return CustomAxios.post(`${Api.UPLOAD_KITCHEN_GALLARY}/${body.id}`, body.image);
};