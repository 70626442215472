import { Api } from '../../../resources/api-constants';
import CustomAxios from '../../../utility/customAxios';
import { PaginationExtractor } from '../../../utility/helpers/pagination-extractor';
import { ISpecialOffer } from '../interfaces/special-order.interface';

export const listSellerSpecialOrdersApi = (query: any) => {
	return CustomAxios.get(`${Api.SELLER_SPECIAL_ORDERS}${PaginationExtractor(query)}`);
};

export const getSpecialOrderDetailsAPi = (id: string) => {
	return CustomAxios.get(`${Api.SPECIAL_ORDER_DETAILS}/${id}`);
};

export const addSpecialOfferApi = (id: string, data: ISpecialOffer) => {
	return CustomAxios.put(`${Api.ADD_SPECIAL_OFFER}/${id}`, data);
};