import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'
import RoleContext from '../provider/Profile.provider'
import { ROLE } from '../utility/enums/role.enum'
import { useTranslation } from 'react-i18next'

const SidemenuComponent: React.FC<any> = () => {
  const { t } = useTranslation()
  const userProfile = useContext(RoleContext)

  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        <NavLink to={ROUTES.HOMEPAGE} className="logo logo-light">
          <span className="logo-sm">
            <img src="/assets/images/logos/akla-beity-appicon.png" alt="" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logos/akla-beity-logoline.png" alt="" />
          </span>
        </NavLink>
        <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      <div id="scrollbar">
        <div className="container-fluid">
          <div id="two-column-menu"></div>
          <ul className="navbar-nav" id="navbar-nav">
            <li className="nav-item">
              <NavLink className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')} to={ROUTES.HOMEPAGE} role="button">
                <i className="ri-dashboard-2-line"></i> <span>{t('Common.Dashboard')}</span>
              </NavLink>
            </li>
            {userProfile.role?.type === ROLE.ADMIN && (
              <li className="nav-item">
                <a className="nav-link menu-link" href="#categories" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
                  <i className="ri-pencil-ruler-2-line"></i> <span>{t('Common.Categories')}</span>
                </a>
                <div className="collapse menu-dropdown" id="categories">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <NavLink to={ROUTES.CATEGORIES_LIST} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                        {t('Common.Categories')}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to={ROUTES.CATEGORIES_CREATE} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                        {t('Common.Create')}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            )}

            {userProfile.role?.type === ROLE.ADMIN && (
              <li className="nav-item">
                <NavLink to={ROUTES.ATTRIBUTES_LIST} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                  <i className="ri-honour-line"></i>
                  <span>{t('Common.Attributes')}</span>
                </NavLink>
              </li>
            )}
            <li className="nav-item">
              <a className="nav-link menu-link" href="#products" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
                <i className="ri-apps-2-line"></i> <span>{t('Common.Products')}</span>
              </a>
              <div className="collapse menu-dropdown" id="products">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <NavLink to={ROUTES.PRODUCTS_LIST} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                      {t('Common.Products')}
                    </NavLink>
                  </li>
                  {userProfile.role?.type == ROLE.SELLER && (
                    <li className="nav-item">
                      <NavLink to={ROUTES.PRODUCTS_CREATE} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                        {t('Common.Create')}
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>

            <li className="nav-item">
              <a className="nav-link menu-link" href="#kitchens" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
                <i className="ri-rocket-line"></i> <span>{t('Common.Kitchens')}</span>
              </a>
              <div className="collapse menu-dropdown" id="kitchens">
                <ul className="nav nav-sm flex-column">
                  {userProfile.role?.type === ROLE.ADMIN && (
                    <li className="nav-item">
                      <NavLink to={ROUTES.KITCHEN_LIST} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                        {t('Common.Kitchens')}
                      </NavLink>
                    </li>
                  )}
                  {userProfile.role?.type === ROLE.SELLER && (
                    <li className="nav-item">
                      <NavLink to={ROUTES.KITCHEN_VIEW} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                        {t('Actions.View')}
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')} to={ROUTES.ORDERS_LIST} role="button">
                <i className="ri-pie-chart-line"></i> <span>{t('Common.Orders')}</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')} to={ROUTES.SPECIAL_ORDERS_LIST} role="button">
                <i className="ri-stack-line"></i> <span>{t('Common.SpecialOrders')}</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')} to={ROUTES.OFFER_LIST} role="button">
                <i className="ri-file-list-3-line"></i> <span>{t('Common.Offers')}</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="sidebar-background"></div>
    </div>
  )
}

export default SidemenuComponent
