import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { loginAction } from '../action/auth.action'
import { useTranslation } from 'react-i18next'
import { getProfileAction } from '../../profile/action/profile.action'
import { getSellerKitchenAction } from '../../kitchen/actions/kitchen.actions'
import { ROLE } from '../../../utility/enums/role.enum'

const SigninPage: React.FC<any> = () => {
  const router = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const auth = useAppSelector((state) => state.auth.contents)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (auth.accessToken) {
      router('/')
    }
  }, [auth, router])

  const loginHandler = (e: any) => {
    e.preventDefault()
    dispatch(loginAction({ email, password })).then((login: any) => {
      if (login.payload.success) {
        dispatch(getProfileAction()).then((res: any) => {
					if (res.payload.data.role?.type == ROLE.SELLER) {
						dispatch(getSellerKitchenAction()).then((kit: any) => {
							if(!kit.payload.success){
								window.localStorage.setItem('StepNum', JSON.stringify(2))
								window.localStorage.setItem('Owner', res.payload.data._id)
								router('/auth/signup')
							}
						})
					}
				})
      }
    })
  }

  return (
    <div className="auth-page-wrapper pt-5">
      <Helmet>
        <script src="/assets/js/pages/particles.app.js" async></script>
        <script src="/assets/js/pages/password-addon.init.js"></script>
      </Helmet>
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div className="bg-overlay"></div>

        <div className="shape">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
        </div>
      </div>

      <div className="auth-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <a href="index.html" className="d-inline-block auth-logo">
                    <img src="/assets/images/logo-light.png" alt="" height="20" />
                  </a>
                </div>
                <p className="mt-3 fs-15 fw-medium">{t('Auth.Intro')}</p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">{t('Auth.Welcom_Back')}</h5>
                  </div>
                  <div className="p-2 mt-4">
                    <form onSubmit={loginHandler}>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          {t('Common.Email_Address')}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder={t('Common.Email_Address')}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <a href="auth-pass-reset-basic.html" className="text-muted">
                            {t('Auth.Forgot_Password')}
                          </a>
                        </div>
                        <label className="form-label" htmlFor="password">
                          {t('Common.Password')}
                        </label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            type="password"
                            className="form-control pe-5 password-input"
                            placeholder={t('Common.Password')}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon">
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                        <label className="form-check-label" htmlFor="auth-remember-check">
                          {t('Auth.Remember_Me')}
                        </label>
                      </div>

                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          {t('Auth.Sign_In')}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-4 text-center">
                <p className="mb-0">
                  {t('Auth.Do_Not_Have_Account')}
                  <Link to="/auth/signup" className="fw-semibold text-primary text-decoration-underline">
                    {t('Auth.Sign_Up')}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SigninPage
