import { Api } from "../../../resources/api-constants";
import CustomAxios from '../../../utility/customAxios';

export const getAllGovernoratesService = () => {
	return CustomAxios.get(`${Api.LOOKUP_GOVERNORATES}`);
};

export const getAllCitiesService = (id: string) => {
	return CustomAxios.get(`${Api.LOOKUP_CITIES}/${id}`);
};
