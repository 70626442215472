import { createAsyncThunk } from '@reduxjs/toolkit'
import { addSellerProduct, deleteSellerProduct, getSellerProduct, listAdminProducts, listSellerProducts, updateSellerProductApi, uploadGallayImageService, uploadMainImageService } from '../services/product.service';
import { IPagination } from '../../../utility/interfaces/pagination.interface';


// Admin Actions
export const listAdminProductsAction = createAsyncThunk('listAdminProductsAction/get', async (data: IPagination, thunkApi) => {
	try {
		const response = await listAdminProducts(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

// Seller Actions
export const listSellerProductsAction = createAsyncThunk('listSellerProductsAction/get', async (data: any, thunkApi) => {
	try {
		const response = await listSellerProducts(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const getSellerProductAction = createAsyncThunk('getSellerProductAction/get', async (id: string, thunkApi) => {
	try {
		const response = await getSellerProduct(id);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const addSellerProductAction = createAsyncThunk('addSellerProductAction/post', async (data: any, thunkApi) => {
	try {
		const response = await addSellerProduct(data)
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
})

export const updateSellerProductAction = createAsyncThunk('updateSellerProductAction/post', async (data: any, thunkApi) => {
	try {
		const response = await updateSellerProductApi(data)
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
})

export const deleteSellerProductAction = createAsyncThunk('deleteSellerProductAction/delete', async (id: string, thunkApi) => {
	try {
		const response = await deleteSellerProduct(id);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const uploadMainImageAction = createAsyncThunk('uploadMainImageAction/post', async (data: { id: string; image: any }, thunkApi) => {
	try {
		const response = await uploadMainImageService(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});

export const uploadGallayImageAction = createAsyncThunk('uploadGallayImageAction/post', async (data: { id: string; image: any }, thunkApi) => {
	try {
		const response = await uploadGallayImageService(data);
		return response.data;
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data);
	}
});
